var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-card-title',[_c('router-link',{attrs:{"to":"/app/administration/tefConfig"},nativeOn:{"click":function($event){}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-2","right":""}},[_vm._v(" mdi-arrow-left-bold ")])],1),_vm._v(" Terminales [ "+_vm._s(_vm.getListTerminalesbyTypeTef[0]['client'])+"] ")],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.copyAlert),expression:"copyAlert"}],attrs:{"type":"info","text":""}},[_vm._v(" "+_vm._s(_vm.msgtokencopiado)+" ")]),_c('br'),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
            'items-per-page-text': 'filas por pagina',
          },"headers":_vm.headers,"items":_vm.getListTerminalesbyTypeTef,"search":_vm.search},scopedSlots:_vm._u([{key:"item.token_key",fn:function(ref){
          var item = ref.item;
return [_c('v-icon',{directives:[{name:"clipboard",rawName:"v-clipboard:copy",value:(item.token_key),expression:"item.token_key",arg:"copy"},{name:"clipboard",rawName:"v-clipboard:success",value:(_vm.onCopy),expression:"onCopy",arg:"success"}],staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]}},{key:"item.data_integration",fn:function(ref){
          var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"1000","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-cellphone-check ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" DATOS INTERAGACIÓN TEF - CLIENTE ["+_vm._s(item.client)+"] - TERMINAL ["+_vm._s(item.code_store)+"] "),_c('v-icon',{staticStyle:{"margin-left":"460px !important"},on:{"click":function($event){dialog.value = false}}},[_vm._v("mdi-close ")])],1),(item.type_tef == 1)?_c('campo-smart-pos',{attrs:{"dataTef":JSON.parse(item.data_integration),"token_key":item.token_key,"code_store":item.code_store}}):_vm._e(),(item.type_tef == 2)?_c('campo-credibanco-apps',{attrs:{"dataTef":JSON.parse(item.data_integration),"token_key":item.token_key,"code_store":item.code_store}}):_vm._e(),(item.type_tef == 4)?_c('campo-credibanco-tramas',{attrs:{"dataTef":JSON.parse(item.data_integration),"token_key":item.token_key,"code_store":item.code_store}}):_vm._e()],1)]}}],null,true)})]}},{key:"item.editar",fn:function(ref){
          var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"600","transition":"dialog-bottom-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"orange darken-3"}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" TIEMPOS CONSULTA TEF - CLIENTE ["+_vm._s(item.client)+"] - TERMINAL ["+_vm._s(item.code_store)+"] "),_c('v-icon',{staticStyle:{"margin-left":"80px !important"},on:{"click":function($event){dialog.value = false}}},[_vm._v("mdi-close ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Tiempo Inicial de Consulta ")]),_c('br'),_c('v-slider',{attrs:{"thumb-size":24,"min":"20","max":"60","color":"red","track-color":"gray","thumb-label":"always"},model:{value:(_vm.dataTimeOuts.time_out_initial),callback:function ($$v) {_vm.$set(_vm.dataTimeOuts, "time_out_initial", $$v)},expression:"dataTimeOuts.time_out_initial"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-subheader',{staticClass:"pl-0"},[_vm._v(" Tiempo Secuencial de Consulta ")]),_c('br'),_c('v-slider',{attrs:{"thumb-size":24,"min":"4","max":"20","color":"red","track-color":"gray","thumb-label":"always"},model:{value:(_vm.dataTimeOuts.time_out_sequential),callback:function ($$v) {_vm.$set(_vm.dataTimeOuts, "time_out_sequential", $$v)},expression:"dataTimeOuts.time_out_sequential"}})],1)],1)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"text":""},on:{"click":function($event){return _vm.updateDataTimeout(item)}}},[_vm._v(" Actualizar ")])],1),_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.successAlert),expression:"successAlert"}],attrs:{"type":"success","text":""}},[_vm._v(" "+_vm._s(_vm.msgSuccessAlert)+" ")])],1)]}}],null,true)})]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }